.th-multiselect {
  padding-bottom: 1.3rem;
  position: relative;
  .th-multiselect-tooltip {
    position: absolute;
    bottom: 100px;
    left: 0px;
    border-radius: 8px;
    color: white;
    background-color: #6ebbb0;
    padding: 8px;
    z-index: 10;
    &::before {
      position: absolute;
      content: ' ';
      bottom: -7px;
      right: 50%;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 7px 7.5px 0 7.5px;
      border-color: #6ebbaf transparent transparent transparent;
    }
  }

  .th-multi-container {
    font-family: inherit;
    position: relative;
    width: 100%;
    .th-multiselect-list {
      &::-webkit-scrollbar {
        width: 0.5em;
      }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }

      &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
      }

      font-family: inherit;
      position: absolute;
      max-height: 25vh;
      overflow-y: scroll;
      border: 1px solid #8b8b8b;
      overflow-x: hidden;
      width: calc(100% - 2px);
      background-color: whitesmoke;
      z-index: 100;
      .th-multiselect-list-option {
        padding: 6px;
        &:hover {
          background-color: rgb(204, 204, 204);
        }
      }
      .selected {
        background-color: rgb(211, 211, 211);
      }
    }
    .th-multi-input {
      -webkit-user-select: none; /* Safari */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* IE10+/Edge */
      user-select: none; /* Standard */
      background-image: url('./arrow-small-down.svg');
      background-repeat: no-repeat;
      background-position-x: calc(100% - 15px);
      background-position-y: center;
      min-width: 140px;
      display: flex;
      flex-grow: 0;
      width: 100%;
      border: 0;
      font-family: 'Montserrat', sans-serif;
      box-sizing: border-box;
      margin-top: 15px;
      padding: 0 10px;
      background-color: #f3f3f3;
      border: 1px solid #8b8b8b;
      border-radius: 0;
      color: #77787b;
      font-size: 14px;
      font-weight: 600;
      line-height: 34px;
      height: 35px;
      outline: none;
      &:focus {
        background-color: #fff;
      }
      .th-multiselect-selection {
        width: calc(100% - 50px);
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}
