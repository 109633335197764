// input {
//   margin: 1rem;
//   width: 20rem;
//   padding: 1rem 0.5rem;
// }
.autocomplete-container {
  position: relative;
  padding-top: 3px;
  input {
    margin-top: 0;
  }
}

.downshift-dropdown {
  position: absolute;
  z-index: 100;
  box-sizing: border-box;
  border: 1px solid #8b8b8b;
  margin: 0 auto;
  width: 100%;
  border-bottom: none;
  &:last-child {
    border: 1px solid #8b8b8b;
  }

  &:first-child {
    border-top: 0;
  }
}

.dropdown-item {
  // width: 100%;
  margin: 0;
  padding: 10px;
  list-style: none;
  border-top: 1px dotted #8b8b8b;
  // padding: 0.5rem;
  cursor: pointer;
  font-size: 1rem;
  text-align: left;
}

  
.error {
  font-size: 12px;
  padding-left: 16px;
  color: #f21818;
  font-style: oblique;
}

.dropdown-button {
  padding: 0.6rem;
  border-radius: 3px;
  background: white;
  cursor: pointer;
}

.popper-div {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
}

.popper-item {
  padding: 0.5rem;
  border-bottom: 1px solid whitesmoke;
}
