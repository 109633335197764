.th-table-wrapper {
  border-top: 1px solid #8b8b8b;
  border-bottom: 1px solid #8b8b8b;
  width: 100%;
  .result-counter {
    background-color: rgb(243, 243, 243);
    width: 100%;
    font-size: 32px;
    color: #5ab2a5;
    padding: 12px;
    padding-top: 36px;
    font-weight: bold;
  }
  .table-footer {
    display: flex;
    justify-content: space-around;
    .footer-controls {
      -webkit-user-select: none; /* Safari */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* IE10+/Edge */
      user-select: none; /* Standard */
      font-size: 15px;
      align-items: center;
      display: flex;
      margin: 15px;
      padding: 15px;
      .footer-control-arrow {
        -webkit-user-select: none; /* Safari */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+/Edge */
        user-select: none; /* Standard */
        font-size: 25px;
        cursor: pointer;
        margin: 0 15px;
        transition: all 150ms ease-in;
        &:hover {
          transition: all 150ms ease-in;
          color: #39bea9;
        }
      }
    }
  }
  .th-table-root {
    border-collapse: collapse;
    width: 100%;

    td {
      //   border: 1px solid black;
    }
    .unselectable {
      cursor: initial;
      padding-bottom: 20px;
      border: none;
    }
    tr {
      cursor: pointer;
      line-height: 2rem;
      font-size: 12px;
      font-weight: 500;
      transition: all 150ms ease-in;
      border-top: 1px solid #8b8b8b;
      border-bottom: 1px solid #8b8b8b;
      overflow: hidden;
      &:nth-child(odd) {
        background-color: #f3f3f3;
      }
      &:hover {
        transition: all 150ms ease-in;
        background-color: rgba(226, 241, 172, 0.226);
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.hover {
  // Everything before the hovered element
  // Since we can't select previous elements, we simply set it as the default and then overwrite subsequent elements
  opacity: 0.8;

  &::before {
    opacity: 0.8;
  }

  // The hovered element
  &:focus,
  &:hover {
    opacity: 1;
  }

  // Everything after the hovered element
  // ~ is the sibling selector https://developer.mozilla.org/en/docs/Web/CSS/General_sibling_selectors
  &:focus ~ &,
  &:hover ~ & {
    &::before {
      //   content: 'A';
    }
  }
}

// If the container isn't hovered, set some default styles
// No IE/Edge support for :focus-within but you can restructure/remove this
// https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-within
.container:not(:focus-within):not(:hover) {
  .hover {
    opacity: 1;
  }
}
