.client-detail-wrapper {
  height: 100%;
  width: 100%;
  padding: 0 5rem;
  .client-detail-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 20px;
  }
  .section-heading {
    // text-align: center;
    color: #5ab2a5;
    font-size: 30px;
  }
}
.clients {
  font-family: 'Montserrat', sans-serif;
}

.search-button {
  cursor: pointer;
  -webkit-appearance: none;
  border: 1px solid #6ebbb0;
  background-color: white;
  font-size: 14px;
  font-family: Montserrat;
  width: 200px;
  padding: 15px 36px;
  color: #6ebbb0;
  font-weight: 500;
  line-height: 30px;
  transition: all 0.2s ease-in;
  &:hover {
    background-color: #6ebbb0;
    color: white;
  }
}

.search-button-invert {
  cursor: pointer;
  -webkit-appearance: none;
  border: 1px solid #6ebbb0;
  color: white;
  font-size: 14px;
  width: 200px;
  font-family: Montserrat;
  padding: 15px 36px;
  background-color: #6ebbb0;
  font-weight: 500;
  line-height: 30px;
  transition: all 0.2s ease-in;
  &:hover {
    background-color: #b9d35e;
  }
}
.addresses-display {
  overflow: auto;
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
  width: 100%;
  min-height: 35px;
  margin-top: 15px;
  padding: 0 5px;
  background-color: #f3f3f3;
  border: 1px solid #8b8b8b;
  border-radius: 0;
  color: #77787b;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  outline: none;
  height: 127px;
}
